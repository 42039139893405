@font-face {
  font-family: 'google_sansregular';
  src: url('./fonts/google-sans-regular/google-sans-regular-webfont.woff2') format('woff2'),
       url('./fonts/google-sans-regular/google-sans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'google_sansbold';
  src: url('./fonts/google-sans-bold/google-sans-bold-webfont.woff2') format('woff2'),
       url('./fonts/google-sans-bold/google-sans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'latoblack';
  src: url('./fonts/lato-black/lato-black-webfont.woff2') format('woff2'),
       url('./fonts/lato-black/lato-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body, html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  max-width: 100%;
  background-color: #0f171e; 
}
